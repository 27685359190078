import { Action } from "@ngrx/store";
import { OrientationDetails } from "../Swagger-Gen-V2/model/orientationDetails";
import { AdministrationFeatureFlags } from "../Swagger-Gen-V2/model/administrationFeatureFlags";

export enum RootActionTypes {
  GetWelcomeOrientationInfo = "Welcome orientation data",
  GetWelcomeOrientationInfoSuccess = "Welcome orientation data success",
  GetWelcomeOrientationInfoFail = "Welcome orientation data fail",
  GetFeatrueFlagsStatus = "Get All Feature Flag Status",
  GetFeatrueFlagsStatusSuccess = "Get All Feature Flag Status Success",
  GetFeatrueFlagsStatusFail = "Get All Feature Flag Status Fail",
  Logout = "User Logout of app",
}

export class GetWelcomeOrientationInfo implements Action {
  readonly type = RootActionTypes.GetWelcomeOrientationInfo;
  IdentityKey = "";
  constructor(public identityKey: string) {
    this.IdentityKey = identityKey;
  }
}
export class GetWelcomeOrientationInfoSuccess implements Action {
  readonly type = RootActionTypes.GetWelcomeOrientationInfoSuccess;
  constructor(public payload: OrientationDetails) {}
}

export class GetWelcomeOrientationInfoFail implements Action {
  readonly type = RootActionTypes.GetWelcomeOrientationInfoFail;
  constructor(public payload: string) {}
}

export class GetFeatrueFlagsStatus implements Action {
  readonly type = RootActionTypes.GetFeatrueFlagsStatus;
  constructor() {}
}

export class GetFeatrueFlagsStatusSuccess implements Action {
  readonly type = RootActionTypes.GetFeatrueFlagsStatusSuccess;
  constructor(public payload: AdministrationFeatureFlags) {}
}

export class GetFeatrueFlagsStatusFail implements Action {
  readonly type = RootActionTypes.GetFeatrueFlagsStatusFail;
  constructor(public payload: string) {}
}

export class Logout implements Action {
  readonly type = RootActionTypes.Logout;
}

export type RootActions =
  | GetWelcomeOrientationInfo
  | GetWelcomeOrientationInfoSuccess
  | GetWelcomeOrientationInfoFail
  | GetFeatrueFlagsStatus
  | GetFeatrueFlagsStatusSuccess
  | GetFeatrueFlagsStatusFail
  | Logout;
