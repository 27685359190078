import { registerPlugin, Plugin } from "@capacitor/core";

interface PingPluginInterface extends Plugin {
  initializePingAuth: (options: {}) => Promise<{ accessToken: string }>;
  fetchUserInfo: (options: {}) => Promise<{ userInfo: string }>;
  logoutUser: (options: {}) => Promise<{ success: boolean }>;
  getAndroidAccessToken: (options: {}) => Promise<{ accessToken: string }>;
}

const PingPlugin = registerPlugin<PingPluginInterface>("PingPlugin");

export default PingPlugin;
