import { NavController } from "@ionic/angular";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { AuthService } from "../../auth/auth.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class IdleLogoutService {
  idleState = "Not started.";
  timedOut = false;
  min: any;
  sec;
  any;
  constructor(
    public navCtrl: NavController,
    public idle: Idle,
    private authService: AuthService
  ) {}

  // ionViewDidLoad() {
  //     this.idle.setIdle(5);  //after 5 sec idle
  //     this.idle.setTimeout(5*60);  //5min countdown
  //     this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

  //     this.idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
  //     this.idle.onTimeout.subscribe(() => {
  //         this.idleState = 'Timed out!';
  //         this.timedOut = true;
  //         this.authService.signOut();
  //         this.navCtrl.navigateRoot('/');  //go to logout page after 5 min idle.
  //     });
  //     this.idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
  //     this.idle.onTimeoutWarning.subscribe((countdown) => {

  //         var data=countdown/60;
  //         this.min=data.toString().split('.')[0];
  //         this.sec=     parseFloat(0+'.'+data.toString().split('.')[1])*60;
  //         this.sec=  (Math.round(this.sec * 100) / 100);

  //         this.idleState = `You'll logout in ${this.min} min and ${this.sec} seconds!`;
  //     });
  //     this.reload();
  // }
  reload() {
    this.idle.watch();
    this.idleState = "Started.";
    this.timedOut = false;
  }
}