import { Injectable, OnDestroy } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { map, takeWhile } from "rxjs/operators";
import * as fromPastPaychecks from "../../../modules/pay/past-paychecks/state/past-paychecks.reducer";
import * as pastPaychecksActions from "../../../modules/pay/past-paychecks/state/past-paychecks.actions";
import * as fromSafetyStats from "../../../modules/safety/components/safety-stats/state/safety-stats.reducer";
import * as safetyStatsActions from "../../../modules/safety/components/safety-stats/state/safety-stats.action";
import * as safetyStatsInfo from "../../../modules/safety/components/safety-stats/state/safety-stats.reducer";
import * as fromRoot from "./../../../state/root.reducer";
import * as rootActions from "./../../../state/root.actions";
import { SubSink } from "subsink";
import { Profile, RequestService } from "src/app/Swagger-Gen-V2";
import * as UserDataInfo from "../../../modules/profile-v2/state/user-profile.reducer";
import * as UserDataActions from "../../../modules/profile-v2/state/user-profile.actions";
import { ProfileUtility } from "../../utilities/profile-utility";
import { ProfileIdsResponse } from "src/app/Swagger-Gen-V2/model/ProfileIdsResponse";
import { BehaviorSubject, forkJoin } from "rxjs";
import { ProfileWorkdayReference } from "src/app/Swagger-Gen-V2/model/profileWorkdayReference";
@Injectable({
  providedIn: "root",
})
export class DispatchService implements OnDestroy {
  subscriptions$ = new SubSink();
  profileInfo: Profile;
  safetyDataTriggered: boolean = false;
  employeeNumber: string;
  profileIdStorageKey: string = "profile-ids";
  profileIds: ProfileIdsResponse;
  routingTypeForBenefits: number = 2;
  isAnnouncement: boolean = true;
  benefitsCount = new BehaviorSubject<number>(0);
  isApiLoading: boolean = false;
  referenceTypeForEthnicity: string = "Ethnicity_ID";
  ethinicityStorageKey: string = "ethinicity_options";
  referenceTypeForMaritalStatus: string = "Marital_Status_ID";
  maritalStorageKey: string = "marital_options";
  referenceTypeForMilitaryStatus: string = "Military_Status_ID";
  militaryStorageKey: string = "military_options";
  referenceTypeForPronoun: string = "Pronoun_ID";
  pronounStorageKey: string = "pronoun_options";
  referenceTypeForDisability: string = "Disability_ID";
  disabilityStorageKey: string = "disability_options";
  referenceTypeForPublicProfile: string = "Public_Profile_Display_Option_ID";
  publicPreferenceStorageKey: string = "public_preference_options";
  constructor(
    private payCheckStore: Store<fromPastPaychecks.State>,
    private safetyStore: Store<fromSafetyStats.State>,
    private rootStore: Store<fromRoot.State>,
    private userStore: Store<UserDataInfo.State>,
    private profileUtility: ProfileUtility,
    private requestService: RequestService
  ) {}

  async getUserV2ProfileInfo(identityKey: string) {
    this.subscriptions$.sink = this.userStore
      .pipe(
        select(UserDataInfo.getUserData),
        takeWhile(() => true)
      )
      .subscribe(async (profileInfo) => {
        this.profileInfo = profileInfo;
        this.employeeNumber = this.profileUtility.getEmployeeNumber(
          this.profileInfo
        );
        if (this.profileInfo === null) {
          this.userStore.dispatch(new UserDataActions.UserProfile(identityKey));
        } else {
          this.subscriptions$.sink = this.userStore
            .pipe(
              select(safetyStatsInfo.getDriverSafetyStats),
              takeWhile(() => true)
            )
            .subscribe((safetyData) => {
              if (safetyData === null && this.safetyDataTriggered === false) {
                this.safetyStore.dispatch(
                  new safetyStatsActions.GetStatsView(
                    identityKey,
                    this.employeeNumber,
                    this.profileInfo.user.stakeholderId
                  )
                );
                this.payCheckStore.dispatch(
                  new pastPaychecksActions.GetPastPaychecks(
                    this.profileInfo.user.workdayId
                  )
                );
                this.safetyDataTriggered = true;
              }
            });
        }
      });
  }

  async getEmployeeNumber(): Promise<string> {
    const profileIds: ProfileIdsResponse = await this.getProfileIds();
    return profileIds.employeeNumber;
  }

  async getWorkdayId(): Promise<string> {
    const profileIds: ProfileIdsResponse = await this.getProfileIds();
    return profileIds.workdayId;
  }

  async getStackholderId(): Promise<number> {
    const profileIds: ProfileIdsResponse = await this.getProfileIds();
    return parseInt(profileIds.stakeholderId);
  }

  getBenefitsCount = async () => {
    this.subscriptions$.sink = this.requestService
      .messagesInboxGet(
        this.routingTypeForBenefits,
        this.isAnnouncement,
        this.profileIds.driverId
      )
      .subscribe(async (response) => {
        const count =
          response?.threads?.filter((obj) => obj.isParticipantRead === false)
            .length ?? 0;
        this.benefitsCount.next(count);
      });
  };

  async getProfileImage(): Promise<string> {
    console.log("Get Profile Image")
    return new Promise((resolve, reject) => {
      const pictureValue = localStorage.getItem("PROFILE_PICTURE_BASE64");
      if (pictureValue) {
        resolve(`data:image/jpeg;base64,${pictureValue}`);
      } else {
        this.subscriptions$.sink = this.userStore
          .pipe(select(UserDataInfo.getUserPicture))
          .subscribe(
            async (response) => {
              if (response) {
                localStorage.setItem("PROFILE_PICTURE_BASE64", response?.image);
                resolve(`data:image/jpeg;base64,${response?.image}`);
              } else {
                this.userStore.dispatch(new UserDataActions.UserPicture());
              }
            },
            (error) => {
              reject(new Error("Failed to fetch user picture"));
            }
          );
      }
    });
  }

  getProfileIds = async () => {
    if (this.isApiLoading === true) {
      return;
    }

    this.isApiLoading = true;
    return new Promise((resolve, reject) => {
      let profileIds = localStorage.getItem(this.profileIdStorageKey);
      if (profileIds) {
        console.log("UserIds Profile Ids from local storage",profileIds);
        this.isApiLoading = false;
        resolve(JSON.parse(profileIds));
      } else {
        this.subscriptions$.sink = this.userStore
          .pipe(select(UserDataInfo.getUserIds))
          .subscribe(
            (profileIds) => {
              console.log("dispatch.service - getprofileIds", profileIds);
              this.isApiLoading = false;
              if (profileIds) {
                localStorage.setItem(
                  this.profileIdStorageKey,
                  JSON.stringify(profileIds)
                );
                resolve(profileIds);
              } else {
                console.log("userstore dispatch called");
                this.userStore.dispatch(new UserDataActions.UserIds());
              }
            },
            (error) => {
              this.isApiLoading = false;
              reject(new Error("Failed to fetch user ids"));
            }
          );
      }
    });
  };

  public homeLoadDispatch(identityKey: string) {
    console.log("dispatch service - homeLoadDispatch", identityKey);
    this.getProfileIds()
      .then(async (profileIds) => {
        this.profileIds = profileIds as ProfileIdsResponse;
        console.log(
          "dispatch service - homeLoadDispatch - this.profileIds",
          this.profileIds
        );
        // Dispatch actions to load user data
        await this.userStore.dispatch(
          new UserDataActions.UserProfileBasicInfo(this.profileIds.workdayId)
        );
        await this.userStore.dispatch(
          new UserDataActions.UserPermissions(this.profileIds.stakeholderId)
        );
        this.safetyStore.dispatch(
          new safetyStatsActions.GetStatsView(
            identityKey,
            this.profileIds.employeeNumber,
            this.profileIds.stakeholderId
          )
        );
        this.payCheckStore.dispatch(
          new pastPaychecksActions.GetPastPaychecks(this.profileIds.workdayId)
        );
        // Load benefits count
        this.getBenefitsCount();
      })
      .catch((error) => {
        console.error("Error loading profile IDs", error);
      });

    // Load profile image
    this.getProfileImage();

    // load profile options
    this.getProfileFieldsOptions();
  }

  async getFeatureFlags() {
    return new Promise((resolve, reject) => {
      this.subscriptions$.sink = this.rootStore
        .pipe(select(fromRoot.getFeatureFlagStatusData))
        .subscribe(
          (featureFlagIinfo) => {
            if (featureFlagIinfo) {
              resolve(featureFlagIinfo);
            } else {
              this.rootStore.dispatch(new rootActions.GetFeatrueFlagsStatus());
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getProfileFieldsOptions() {
    const ethnicitiesOptions$ = this.getWorkdayReferences(
      this.referenceTypeForEthnicity,
      this.ethinicityStorageKey
    );
    const maritalStatusOptions$ = this.getWorkdayReferences(
      this.referenceTypeForMaritalStatus,
      this.maritalStorageKey
    );
    const militaryStatusOptions$ = this.getWorkdayReferences(
      this.referenceTypeForMilitaryStatus,
      this.militaryStorageKey
    );
    const pronounOptions$ = this.getWorkdayReferences(
      this.referenceTypeForPronoun,
      this.pronounStorageKey
    );
    const publicProfileOptions$ = this.getWorkdayReferences(
      this.referenceTypeForPublicProfile,
      this.publicPreferenceStorageKey
    );
    const disabilityOptions$ = this.getWorkdayReferences(
      this.referenceTypeForDisability,
      this.disabilityStorageKey
    );

    return forkJoin([
      ethnicitiesOptions$,
      maritalStatusOptions$,
      militaryStatusOptions$,
      pronounOptions$,
      publicProfileOptions$,
      disabilityOptions$,
    ]).pipe(
      map(
        ([
          ethnicitiesOptions,
          maritalStatusOptions,
          militaryStatusOptions,
          pronounOptions,
          publicProfileOptions,
          disabilityOptions,
        ]) => {
          return {
            ethnicitiesOptions,
            maritalStatusOptions,
            militaryStatusOptions,
            pronounOptions,
            publicProfileOptions,
            disabilityOptions,
          };
        }
      )
    );
  }

  getWorkdayReferences(referenceType: string, storageKey: string) {
    return new Promise<Array<ProfileWorkdayReference>>((resolve, reject) => {
      const storageValue: Array<ProfileWorkdayReference> = JSON.parse(
        localStorage.getItem(storageKey)
      );
      if (storageValue) {
        resolve(storageValue);
      } else {
        this.subscriptions$.sink = this.requestService
          .profileWorkdayReferencesGet(referenceType)
          .subscribe(
            async (response) => {
              if (response && response.length > 0) {
                localStorage.setItem(storageKey, JSON.stringify(response));
                resolve(response);
              } else {
                reject(new Error("No references found in the response."));
              }
            },
            (error) => {
              reject(new Error(`Request failed: ${error.message || error}`));
            }
          );
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}
