import { Injectable, Injector } from "@angular/core";
import { Observable, of } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";
import { Action } from "@ngrx/store";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { RequestService } from "src/app/Swagger-Gen-V2";
import * as UserProfileActions from "./user-profile.actions";

@Injectable()
export class GetUserProfileEffects {
  userProfile: RequestService = this.injector.get(RequestService);
  constructor(private injector: Injector, private actions$: Actions) {}

  @Effect()
  userData$: Observable<Action> = this.actions$.pipe(
    ofType(UserProfileActions.UserProfileActionTypes.UserProfile),
    mergeMap((action) =>
      this.userProfile
        .profileGet((action as UserProfileActions.UserProfile).identityKey)
        .pipe(
          map(
            (profileData) =>
              new UserProfileActions.UserProfileSuccess(profileData)
          ),
          catchError((err) => of(new UserProfileActions.UserProfileFail(err)))
        )
    )
  );

  @Effect()
  userProfileInfoBasicData$: Observable<Action> = this.actions$.pipe(
    ofType(UserProfileActions.UserProfileBasicInfoActionTypes.UserBasicInfo),
    mergeMap((action) =>
      this.userProfile
        .profileInfoGet(
          (action as UserProfileActions.UserProfileBasicInfo).workdayId
        )
        .pipe(
          map(
            (profileData) =>
              new UserProfileActions.UserProfileBasicInfoSuccess(profileData)
          ),
          catchError((err) =>
            of(new UserProfileActions.UserProfileBasicInfoFail(err))
          )
        )
    )
  );

  @Effect()
  userProfileFleetManagerData$: Observable<Action> = this.actions$.pipe(
    ofType(
      UserProfileActions.UserProfileFleetManagerActionTypes
        .UserProfileFleetManager
    ),
    mergeMap((action) =>
      this.userProfile
        .profileFleetmanagersGet(
          (action as UserProfileActions.UserProfileFleetManager).stakeHolderId
        )
        .pipe(
          map((profileData) => {
            return new UserProfileActions.UserProfileFleetManagerSuccess(
              profileData
            );
          }),
          catchError((err) =>
            of(new UserProfileActions.UserProfileFleetManagerFail(err))
          )
        )
    )
  );

  @Effect()
  userDivisonInfoData$: Observable<Action> = this.actions$.pipe(
    ofType(UserProfileActions.UserDivisonInfoActionTypes.UserDivisonInfo),
    mergeMap((action) =>
      this.userProfile
        .profileDivisionGet(
          (action as UserProfileActions.UserProfileFleetManager).stakeHolderId
        )
        .pipe(
          map((profileData) => {
            return new UserProfileActions.UserDivisonInfoSuccess(profileData);
          }),
          catchError((err) =>
            of(new UserProfileActions.UserDivisonInfoFail(err))
          )
        )
    )
  );

  @Effect()
  useIdsData$: Observable<Action> = this.actions$.pipe(
    ofType(UserProfileActions.UserIdsActionTypes.UserIds),
    mergeMap((action) =>
      this.userProfile.profileIdsGet().pipe(
        map((profileData) => {
          console.log("profileData", profileData);
          return new UserProfileActions.UserIdsSuccess(profileData);
        }),
        catchError((err) => {
          console.log("request service error", err);
          return of(new UserProfileActions.UserIdsFail(err));
        })
      )
    )
  );

  @Effect()
  usePicturesData$: Observable<Action> = this.actions$.pipe(
    ofType(UserProfileActions.UserPictureActionTypes.UserPicture),
    mergeMap((action) =>
      this.userProfile.profileImageGet().pipe(
        map((profileData) => {
          return new UserProfileActions.UserPictureSuccess(profileData);
        }),
        catchError((err) => of(new UserProfileActions.UserPictureFail(err)))
      )
    )
  );

  @Effect()
  usePermissionsData$: Observable<Action> = this.actions$.pipe(
    ofType(UserProfileActions.UserPermissionsActionTypes.UserPermissions),
    mergeMap((action) =>
      this.userProfile
        .profilePermissionsGet(
          (action as UserProfileActions.UserPermissions).stakeHolderId
        )
        .pipe(
          map((profileData) => {
            return new UserProfileActions.UserPermissionsSuccess(profileData);
          }),
          catchError((err) =>
            of(new UserProfileActions.UserPermissionsFail(err))
          )
        )
    )
  );
}
