/**
 * Drive Werner Pro API
 * Drive Werner Pro API
 *
 * OpenAPI spec version: v2
 * Contact: prodeng_BacklogAvengers@Werner.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from "@angular/common/http";
import { CustomHttpUrlEncodingCodec } from "../encoder";

import { map, Observable } from "rxjs";

import { ContactsResponse } from "../model/contactsResponse";
import { FeedbackDetails } from "../model/feedbackDetails";
import { Benefits } from "../model/benefits";
import { FeedbackQuestionnaire } from "../model/feedbackQuestionnaire";
import { OpportunityCity } from "../model/opportunityCity";
import { OpportunityCityFilter } from "../model/opportunityCityFilter";
import { DriverOpportunity } from "../model/driverOpportunity";
import { Opportunity } from "../model/opportunity";
import { OpportunityFilter } from "../model/opportunityFilter";
import { OpportunitySelection } from "../model/opportunitySelection";
import { PointOfInterestResponse } from "../model/pointOfInterestResponse";
import { Preferences } from "../model/Preferences";
import { Profile } from "../model/profile";
import { ReferralCriteria } from "../model/referralCriteria";
import { SafetyStats } from "../model/SafetyStats";
import { SafetyCsaDetails } from "../model/SafetyCsaDetails";
import { JobTrip } from "../model/jobTrip";
import { JobTripType } from "../model/jobTripType";
import { BASE_PATH } from "../variables";
import { ConfigurationV2 } from "../configurationV2";
import { MessagesResponse } from "../model/messagesResponse";
import { WernerDwpContractsLogicJobEquipment } from "../model/wernerDwpContractsLogicJobEquipment";
import { WernerDwpContractsLogicJobTrip } from "../model/wernerDwpContractsLogicJobTrip";
import { WernerDwpContractsLogicJobTrailerReservation } from "../model/wernerDwpContractsLogicJobTrailerReservation";
import { TelemetryResponse } from "../model/TelemetryResponse";
import { Telemetry } from "../model/telemetry";
import { ProfileWorkdayReference } from "../model/profileWorkdayReference";
import { Paperwork } from "../model/Paperwork";
import { PaperworkImage } from "../model/PaperworkImage";
import { ScannedPaperwork } from "../model/ScannedPaperwork";
import { PayCheck } from "../model/payCheck";
import { PayCheckSummary } from "../model/payCheckSummary";
import { AdministrationFeatureFlags } from "../model/administrationFeatureFlags";
import { shareStory } from "../model/shareStory";
import { ProfilePinResponse } from "../model/profilePinResponse";
import { ProfileChallengeQuestionResponse } from "../model/profileChallengeQuestionResponse";
import { ProfileChallengeQuestion } from "../model/profileChallengeQuestion";
import { MapFeedback } from "../model/mapFeedback";
import { ChallengeQuestion } from "../model/challengeQuestion";
import { AdministrationMessageBot } from "../model/administrationMessageBot";
import { CompanyNewsArticle } from "../model/companyNewsArticle";
import { OrientationDetails } from "../model/orientationDetails";
import { Links } from "../model/Links";
import { UserAgreementResponse } from "../model/userAgreementResponse";
import { UserAgreement } from "../model/userAgreement";
import { ReferenceLibraryLibraryMetadata } from "../model/referenceLibraryLibraryMetadata";
import { ReferenceLibrarySectionItem } from "../model/referenceLibrarySectionItem";
import { ReferenceLibrarySubSectionItem } from "../model/referenceLibrarySubSectionItem";
import { ReferenceLibraryQuickLinkItem } from "../model/referenceLibraryQuickLinkItem";
import { MessagesRecipient } from "../model/messagesRecipient";
import { MessageThreadResponse } from "../model/messageThreadResponse";
import { MessageThreadCriteria } from "../model/messageThreadCriteria";
import { ComposeMessageCriteria } from "../model/composeMessageCriteria";
import { ReplyMessageCriteria } from "../model/replyMessageCriteria";
import { MessageThreadDetailResponse } from "../model/messageThreadDetailResponse";
import { MessagesThreadListResponse } from "../model/messagesThreadListResponse";
import { DrivingOptionsQuestionnaire } from "../model/drivingOptionsQuestionnaire";
import { DrivingOptionsSaveDrivingOptionCriteria } from "../model/drivingOptionsSaveDrivingOptionCriteria";
import { DrivingOptionsQuestionnaireValidationModel } from "../model/drivingOptionsQuestionnaireValidationModel";
import { BannerMessage } from "../model/bannerMessage";
import { GamificationBalance } from "../model/gamificationBalance";
import { GamificationBadgeResponse } from "../model/gamificationBadgeResponse";
import { ReferralsReferralMessage } from "../model/ReferralsReferralMessage";
import { ReferenceLibraryReferenceSection } from "../model/referenceLibraryReferenceSection";
import { UrgentCare } from "../model/urgentCare";
import { JobLoadAssignment } from "../model/jobLoadAssignment";
import { JobLoad } from "../model/jobLoad";
import { ProfileFleetManagersResponse } from "../model/ProfileFleetManagersResponse";
import { ProfileIdsResponse } from "../model/ProfileIdsResponse";
import { ProfileImageResponse } from "../model/ProfileImageResponse";
import { ProfileInfo } from "../model/ProfileInfo";
import { ProfileDivisionInfoResponse } from "../model/ProfileDivisionInfoResponse";
import { ProfilePermissionResponse } from "../model/ProfilePermissionResponse";

@Injectable()
export class RequestService {
  protected basePath = "/";
  public defaultHeaders = new HttpHeaders();
  public configuration = new ConfigurationV2();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: ConfigurationV2
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = "multipart/form-data";
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public citiesGet(
    body?: OpportunityCityFilter,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<OpportunityCity>>;
  public citiesGet(
    body?: OpportunityCityFilter,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<OpportunityCity>>>;
  public citiesGet(
    body?: OpportunityCityFilter,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<OpportunityCity>>>;
  public citiesGet(
    body?: OpportunityCityFilter,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }
    let params = new HttpParams().set("keyword", body.keyword);
    return this.httpClient.request<Array<OpportunityCity>>(
      "get",
      `${this.basePath}/cities`,
      {
        params: params,
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public contactsGet(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<ContactsResponse>;
  public contactsGet(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<ContactsResponse>>;
  public contactsGet(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<ContactsResponse>>;
  public contactsGet(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ContactsResponse>(
      "get",
      `${this.basePath}/contacts`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public driveropportunitiesGet(
    body?: DriverOpportunity,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<Opportunity>>;
  public driveropportunitiesGet(
    body?: DriverOpportunity,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Opportunity>>>;
  public driveropportunitiesGet(
    body?: DriverOpportunity,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Opportunity>>>;
  public driveropportunitiesGet(
    body?: DriverOpportunity,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }
    let params = new HttpParams().set("driverId", body.driverId);
    return this.httpClient.request<Array<Opportunity>>(
      "get",
      `${this.basePath}/driveropportunities`,
      {
        params: params,
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public feedbackPost(
    body?: FeedbackDetails,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public feedbackPost(
    body?: FeedbackDetails,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public feedbackPost(
    body?: FeedbackDetails,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public feedbackPost(
    body?: FeedbackDetails,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<any>("post", `${this.basePath}/feedback`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   *
   * @param locationId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public feedbackQuestionnaireGet(
    locationId?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<FeedbackQuestionnaire>;
  public feedbackQuestionnaireGet(
    locationId?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<FeedbackQuestionnaire>>;
  public feedbackQuestionnaireGet(
    locationId?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<FeedbackQuestionnaire>>;
  public feedbackQuestionnaireGet(
    locationId?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (locationId !== undefined && locationId !== null) {
      queryParameters = queryParameters.set("locationId", <any>locationId);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<FeedbackQuestionnaire>(
      "get",
      `${this.basePath}/feedback/questionnaire`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public opportunitiesGet(
    body?: OpportunityFilter,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<Opportunity>>;
  public opportunitiesGet(
    body?: OpportunityFilter,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Opportunity>>>;
  public opportunitiesGet(
    body?: OpportunityFilter,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Opportunity>>>;
  public opportunitiesGet(
    body?: OpportunityFilter,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }
    // pass zipcode, only it's available
    if (body.zipCode)
      var params = new HttpParams().set("zipCode", body.zipCode);
    else
      var params = new HttpParams()
        .set("cityCode", body.cityCode)
        .set("stateCode", body.stateCode);

    return this.httpClient.request<Array<Opportunity>>(
      "get",
      `${this.basePath}/opportunities`,
      {
        params: params,
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param cityCode
   * @param stateCode
   * @param zipCode
   * @param accountCode
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public opportunityGet(
    cityCode?: string,
    stateCode?: string,
    zipCode?: string,
    accountCode?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Opportunity>;
  public opportunityGet(
    cityCode?: string,
    stateCode?: string,
    zipCode?: string,
    accountCode?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Opportunity>>;
  public opportunityGet(
    cityCode?: string,
    stateCode?: string,
    zipCode?: string,
    accountCode?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Opportunity>>;
  public opportunityGet(
    cityCode?: string,
    stateCode?: string,
    zipCode?: string,
    accountCode?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (cityCode !== undefined && cityCode !== null) {
      queryParameters = queryParameters.set("cityCode", <any>cityCode);
    }
    if (stateCode !== undefined && stateCode !== null) {
      queryParameters = queryParameters.set("stateCode", <any>stateCode);
    }
    if (zipCode !== undefined && zipCode !== null) {
      queryParameters = queryParameters.set("zipCode", <any>zipCode);
    }
    if (accountCode !== undefined && accountCode !== null) {
      queryParameters = queryParameters.set("accountCode", <any>accountCode);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Opportunity>(
      "get",
      `${this.basePath}/opportunity`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public opportunitySelectionPost(
    body?: OpportunitySelection,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<OpportunitySelection>;
  public opportunitySelectionPost(
    body?: OpportunitySelection,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<OpportunitySelection>>;
  public opportunitySelectionPost(
    body?: OpportunitySelection,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<OpportunitySelection>>;
  public opportunitySelectionPost(
    body?: OpportunitySelection,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<OpportunitySelection>(
      "post",
      `${this.basePath}/opportunitySelection`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param originZip
   * @param destZip
   * @param originPosition
   * @param destPosition
   * @param routeBuffer
   * @param tripId
   * @param tourId
   * @param limit
   * @param offSet
   * @param allPoi
   * @param terminals
   * @param truckStops
   * @param fuelStops
   * @param truckWashes
   * @param scales
   * @param chainBanks
   * @param dropYards
   * @param cityState
   * @param magicKey
   * @param restAreas
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public poisGet(
    originZip?: string,
    destZip?: string,
    originPosition?: string,
    destPosition?: string,
    routeBuffer?: number,
    tripId?: string,
    tourId?: number,
    limit?: number,
    offSet?: number,
    allPoi?: boolean,
    terminals?: boolean,
    truckStops?: boolean,
    fuelStops?: boolean,
    truckWashes?: boolean,
    scales?: boolean,
    chainBanks?: boolean,
    dropYards?: boolean,
    cityState?: string,
    magicKey?: string,
    restAreas?: boolean,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<PointOfInterestResponse>;
  public poisGet(
    originZip?: string,
    destZip?: string,
    originPosition?: string,
    destPosition?: string,
    routeBuffer?: number,
    tripId?: string,
    tourId?: number,
    limit?: number,
    offSet?: number,
    allPoi?: boolean,
    terminals?: boolean,
    truckStops?: boolean,
    fuelStops?: boolean,
    truckWashes?: boolean,
    scales?: boolean,
    chainBanks?: boolean,
    dropYards?: boolean,
    cityState?: string,
    magicKey?: string,
    restAreas?: boolean,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<PointOfInterestResponse>>;
  public poisGet(
    originZip?: string,
    destZip?: string,
    originPosition?: string,
    destPosition?: string,
    routeBuffer?: number,
    tripId?: string,
    tourId?: number,
    limit?: number,
    offSet?: number,
    allPoi?: boolean,
    terminals?: boolean,
    truckStops?: boolean,
    fuelStops?: boolean,
    truckWashes?: boolean,
    scales?: boolean,
    chainBanks?: boolean,
    dropYards?: boolean,
    cityState?: string,
    magicKey?: string,
    restAreas?: boolean,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<PointOfInterestResponse>>;
  public poisGet(
    originZip?: string,
    destZip?: string,
    originPosition?: string,
    destPosition?: string,
    routeBuffer?: number,
    tripId?: string,
    tourId?: number,
    limit?: number,
    offSet?: number,
    allPoi?: boolean,
    terminals?: boolean,
    truckStops?: boolean,
    fuelStops?: boolean,
    truckWashes?: boolean,
    scales?: boolean,
    chainBanks?: boolean,
    dropYards?: boolean,
    cityState?: string,
    magicKey?: string,
    restAreas?: boolean,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (originZip !== undefined && originZip !== null) {
      queryParameters = queryParameters.set("originZip", <any>originZip);
    }
    if (destZip !== undefined && destZip !== null) {
      queryParameters = queryParameters.set("destZip", <any>destZip);
    }
    if (originPosition !== undefined && originPosition !== null) {
      queryParameters = queryParameters.set(
        "originPosition",
        <any>originPosition
      );
    }
    if (destPosition !== undefined && destPosition !== null) {
      queryParameters = queryParameters.set("destPosition", <any>destPosition);
    }
    if (routeBuffer !== undefined && routeBuffer !== null) {
      queryParameters = queryParameters.set("routeBuffer", <any>routeBuffer);
    }
    if (tripId !== undefined && tripId !== null) {
      queryParameters = queryParameters.set("tripId", <any>tripId);
    }
    if (tourId !== undefined && tourId !== null) {
      queryParameters = queryParameters.set("tourId", <any>tourId);
    }
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set("limit", <any>limit);
    }
    if (offSet !== undefined && offSet !== null) {
      queryParameters = queryParameters.set("offSet", <any>offSet);
    }
    if (allPoi !== undefined && allPoi !== null) {
      queryParameters = queryParameters.set("allPoi", <any>allPoi);
    }
    if (terminals !== undefined && terminals !== null) {
      queryParameters = queryParameters.set("terminals", <any>terminals);
    }
    if (truckStops !== undefined && truckStops !== null) {
      queryParameters = queryParameters.set("truckStops", <any>truckStops);
    }
    if (fuelStops !== undefined && fuelStops !== null) {
      queryParameters = queryParameters.set("fuelStops", <any>fuelStops);
    }
    if (truckWashes !== undefined && truckWashes !== null) {
      queryParameters = queryParameters.set("truckWashes", <any>truckWashes);
    }
    if (scales !== undefined && scales !== null) {
      queryParameters = queryParameters.set("scales", <any>scales);
    }
    if (chainBanks !== undefined && chainBanks !== null) {
      queryParameters = queryParameters.set("chainBanks", <any>chainBanks);
    }
    if (dropYards !== undefined && dropYards !== null) {
      queryParameters = queryParameters.set("dropYards", <any>dropYards);
    }
    if (cityState !== undefined && cityState !== null) {
      queryParameters = queryParameters.set("cityState", <any>cityState);
    }
    if (magicKey !== undefined && magicKey !== null) {
      queryParameters = queryParameters.set("magicKey", <any>magicKey);
    }
    if (restAreas !== undefined && restAreas !== null) {
      queryParameters = queryParameters.set("restAreas", <any>restAreas);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<PointOfInterestResponse>(
      "get",
      `${this.basePath}/pois`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param userId
   * @param deviceId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public preferencesGet(
    userId?: string,
    deviceId?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Preferences>;
  public preferencesGet(
    userId?: string,
    deviceId?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Preferences>>;
  public preferencesGet(
    userId?: string,
    deviceId?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Preferences>>;
  public preferencesGet(
    userId?: string,
    deviceId?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (userId !== undefined && userId !== null) {
      queryParameters = queryParameters.set("userId", <any>userId);
    }
    if (deviceId !== undefined && deviceId !== null) {
      queryParameters = queryParameters.set("deviceId", <any>deviceId);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Preferences>(
      "get",
      `${this.basePath}/preferences`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public preferencesPost(
    body?: Preferences,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public preferencesPost(
    body?: Preferences,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public preferencesPost(
    body?: Preferences,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public preferencesPost(
    body?: Preferences,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<any>(
      "post",
      `${this.basePath}/preferences`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param driverId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public profileGet(
    driverId?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Profile>;
  public profileGet(
    driverId?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Profile>>;
  public profileGet(
    driverId?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Profile>>;
  public profileGet(
    driverId?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });

    if (driverId === null || driverId === undefined) {
      throw new Error(
        "Required parameter driverId was null or undefined when calling profileGet."
      );
    }

    if (driverId !== undefined && driverId !== null) {
      queryParameters = queryParameters.set("driverId", <any>driverId);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Profile>("get", `${this.basePath}/profile`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public profilePatch(
    body?: Profile,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public profilePatch(
    body?: Profile,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public profilePatch(
    body?: Profile,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public profilePatch(
    body?: Profile,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<any>("patch", `${this.basePath}/profile`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   *
   * @param referenceType
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public profileWorkdayReferencesGet(
    referenceType?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<ProfileWorkdayReference>>;
  public profileWorkdayReferencesGet(
    referenceType?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<ProfileWorkdayReference>>>;
  public profileWorkdayReferencesGet(
    referenceType?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<ProfileWorkdayReference>>>;
  public profileWorkdayReferencesGet(
    referenceType?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (referenceType !== undefined && referenceType !== null) {
      queryParameters = queryParameters.set(
        "referenceType",
        <any>referenceType
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<ProfileWorkdayReference>>(
      "get",
      `${this.basePath}/profile/WorkdayReferences`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public referralPost(
    body?: ReferralCriteria,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public referralPost(
    body?: ReferralCriteria,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public referralPost(
    body?: ReferralCriteria,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public referralPost(
    body?: ReferralCriteria,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<any>("post", `${this.basePath}/referral`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   *
   * @param employeeNumber
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public safetyAwardsGet(
    employeeNumber?: string,
    stakeholderId?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<SafetyStats>;
  public safetyAwardsGet(
    employeeNumber?: string,
    stakeholderId?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<SafetyStats>>;
  public safetyAwardsGet(
    employeeNumber?: string,
    stakeholderId?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<SafetyStats>>;
  public safetyAwardsGet(
    employeeNumber?: string,
    stakeholderId?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (employeeNumber !== undefined && employeeNumber !== null) {
      queryParameters = queryParameters.set(
        "employeeNumber",
        <any>employeeNumber
      );
    }
    if (stakeholderId !== undefined && stakeholderId !== null) {
      queryParameters = queryParameters.set(
        "stakeholderId",
        <any>stakeholderId
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<SafetyStats>(
      "get",
      `${this.basePath}/safety/awards`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param employeeNumber
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public safetyCsaGet(
    employeeNumber?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<SafetyCsaDetails>;
  public safetyCsaGet(
    employeeNumber?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<SafetyCsaDetails>>;
  public safetyCsaGet(
    employeeNumber?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<SafetyCsaDetails>>;
  public safetyCsaGet(
    employeeNumber?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (employeeNumber !== undefined && employeeNumber !== null) {
      queryParameters = queryParameters.set(
        "employeeNumber",
        <any>employeeNumber
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<SafetyCsaDetails>(
      "get",
      `${this.basePath}/safety/csa`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public safetyElearningGet(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<string>;
  public safetyElearningGet(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<string>>;
  public safetyElearningGet(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<string>>;
  public safetyElearningGet(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<string>(
      "get",
      `${this.basePath}/safety/elearning`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param type
   * @param employeeNumber
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public tripsGet(
    type: JobTripType,
    employeeNumber: number,
    identityKey?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<JobTrip>>;
  public tripsGet(
    type: JobTripType,
    employeeNumber: number,
    identityKey?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<JobTrip>>>;
  public tripsGet(
    type: JobTripType,
    employeeNumber: number,
    identityKey?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<JobTrip>>>;
  public tripsGet(
    type: JobTripType,
    employeeNumber: number,
    identityKey?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (type === null || type === undefined) {
      throw new Error(
        "Required parameter type was null or undefined when calling tripsGet."
      );
    }

    if (employeeNumber === null || employeeNumber === undefined) {
      throw new Error(
        "Required parameter employeeNumber was null or undefined when calling tripsGet."
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (type !== undefined && type !== null) {
      queryParameters = queryParameters.set("type", <any>type);
    }
    if (employeeNumber !== undefined && employeeNumber !== null) {
      queryParameters = queryParameters.set(
        "employeeNumber",
        <any>employeeNumber
      );
    }
    if (identityKey !== undefined && identityKey !== null) {
      queryParameters = queryParameters.set("identityKey", <any>identityKey);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<JobTrip>>(
      "get",
      `${this.basePath}/trips`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param includeInactive
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public urgentcareGet(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<UrgentCare>;
  public urgentcareGet(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<UrgentCare>>;
  public urgentcareGet(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<UrgentCare>>;
  public urgentcareGet(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<UrgentCare>(
      "get",
      `${this.basePath}/urgentcare`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param includeInactive
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public benefitsGet(
    includeInactive?: boolean,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<Benefits>>;
  public benefitsGet(
    includeInactive?: boolean,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Benefits>>>;
  public benefitsGet(
    includeInactive?: boolean,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Benefits>>>;
  public benefitsGet(
    includeInactive?: boolean,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (includeInactive !== undefined && includeInactive !== null) {
      queryParameters = queryParameters.set(
        "includeInactive",
        <any>includeInactive
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<Benefits>>(
      "get",
      `${this.basePath}/benefits`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param employeeNumber
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public trailerreservationGet(
    employeeNumber: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<WernerDwpContractsLogicJobTrailerReservation>;
  public trailerreservationGet(
    employeeNumber: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<WernerDwpContractsLogicJobTrailerReservation>>;
  public trailerreservationGet(
    employeeNumber: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<WernerDwpContractsLogicJobTrailerReservation>>;
  public trailerreservationGet(
    employeeNumber: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (employeeNumber === null || employeeNumber === undefined) {
      throw new Error(
        "Required parameter employeeNumber was null or undefined when calling trailerreservationGet."
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (employeeNumber !== undefined && employeeNumber !== null) {
      queryParameters = queryParameters.set(
        "employeeNumber",
        <any>employeeNumber
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<WernerDwpContractsLogicJobTrailerReservation>(
      "get",
      `${this.basePath}/trailerreservation`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param trailerNumbers
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public trailersGet(
    trailerNumbers: Array<string>,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<WernerDwpContractsLogicJobEquipment>>;
  public trailersGet(
    trailerNumbers: Array<string>,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<WernerDwpContractsLogicJobEquipment>>>;
  public trailersGet(
    trailerNumbers: Array<string>,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<WernerDwpContractsLogicJobEquipment>>>;
  public trailersGet(
    trailerNumbers: Array<string>,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (trailerNumbers === null || trailerNumbers === undefined) {
      throw new Error(
        "Required parameter trailerNumbers was null or undefined when calling trailersGet."
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (trailerNumbers) {
      trailerNumbers.forEach((element) => {
        queryParameters = queryParameters.append(
          "trailerNumbers",
          <any>element
        );
      });
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<WernerDwpContractsLogicJobEquipment>>(
      "get",
      `${this.basePath}/trailers`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param employeeNumber
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public tripsCurrentGet(
    employeeNumber: number,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<WernerDwpContractsLogicJobTrip>;
  public tripsCurrentGet(
    employeeNumber: number,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<WernerDwpContractsLogicJobTrip>>;
  public tripsCurrentGet(
    employeeNumber: number,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<WernerDwpContractsLogicJobTrip>>;
  public tripsCurrentGet(
    employeeNumber: number,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (employeeNumber === null || employeeNumber === undefined) {
      throw new Error(
        "Required parameter employeeNumber was null or undefined when calling tripsCurrentGet."
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (employeeNumber !== undefined && employeeNumber !== null) {
      queryParameters = queryParameters.set(
        "employeeNumber",
        <any>employeeNumber
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<WernerDwpContractsLogicJobTrip>(
      "get",
      `${this.basePath}/trips/current`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param locationCode
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public sitesafetyalerturlGet(
    locationCode: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<string>;
  public sitesafetyalerturlGet(
    locationCode: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<string>>;
  public sitesafetyalerturlGet(
    locationCode: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<string>>;
  public sitesafetyalerturlGet(
    locationCode: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (locationCode === null || locationCode === undefined) {
      throw new Error(
        "Required parameter locationCode was null or undefined when calling sitesafetyalerturlGet."
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (locationCode !== undefined && locationCode !== null) {
      queryParameters = queryParameters.set("locationCode", <any>locationCode);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<string>(
      "get",
      `${this.basePath}/sitesafetyalerturl`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param routingType
   * @param isAnnouncement
   * @param identityKey
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public messagesInboxGet(
    routingType?: number,
    isAnnouncement?: boolean,
    identityKey?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<MessagesResponse>;
  public messagesInboxGet(
    routingType?: number,
    isAnnouncement?: boolean,
    identityKey?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<MessagesResponse>>;
  public messagesInboxGet(
    routingType?: number,
    isAnnouncement?: boolean,
    identityKey?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<MessagesResponse>>;
  public messagesInboxGet(
    routingType?: number,
    isAnnouncement?: boolean,
    identityKey?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (routingType !== undefined && routingType !== null) {
      queryParameters = queryParameters.set("routingType", <any>routingType);
    }
    if (isAnnouncement !== undefined && isAnnouncement !== null) {
      queryParameters = queryParameters.set(
        "isAnnouncement",
        <any>isAnnouncement
      );
    }
    if (identityKey !== undefined && identityKey !== null) {
      queryParameters = queryParameters.set("identityKey", <any>identityKey);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<MessagesResponse>(
      "get",
      `${this.basePath}/messages/inbox`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public telemetryPost(
    body?: Telemetry,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<TelemetryResponse>;
  public telemetryPost(
    body?: Telemetry,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<TelemetryResponse>>;
  public telemetryPost(
    body?: Telemetry,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<TelemetryResponse>>;
  public telemetryPost(
    body?: Telemetry,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<TelemetryResponse>(
      "post",
      `${this.basePath}/telemetry`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public paperworkPost(
    body?: Paperwork,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<PaperworkImage>;
  public paperworkPost(
    body?: Paperwork,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<PaperworkImage>>;
  public paperworkPost(
    body?: Paperwork,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<PaperworkImage>>;
  public paperworkPost(
    body?: Paperwork,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<PaperworkImage>(
      "post",
      `${this.basePath}/paperwork`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param tripId
   * @param identityKey
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public paperworklabelGet(
    tripId?: string,
    identityKey?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<ScannedPaperwork>>;
  public paperworklabelGet(
    tripId?: string,
    identityKey?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<ScannedPaperwork>>>;
  public paperworklabelGet(
    tripId?: string,
    identityKey?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<ScannedPaperwork>>>;
  public paperworklabelGet(
    tripId?: string,
    identityKey?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (tripId !== undefined && tripId !== null) {
      queryParameters = queryParameters.set("tripId", <any>tripId);
    }
    if (identityKey !== undefined && identityKey !== null) {
      queryParameters = queryParameters.set("identityKey", <any>identityKey);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<ScannedPaperwork>>(
      "get",
      `${this.basePath}/paperworklabel`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public paperworklabelPost(
    body?: ScannedPaperwork,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public paperworklabelPost(
    body?: ScannedPaperwork,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public paperworklabelPost(
    body?: ScannedPaperwork,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public paperworklabelPost(
    body?: ScannedPaperwork,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    console.log(body);

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<any>(
      "post",
      `${this.basePath}/paperworklabel`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public paperworklabelPut(
    body?: ScannedPaperwork,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public paperworklabelPut(
    body?: ScannedPaperwork,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public paperworklabelPut(
    body?: ScannedPaperwork,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public paperworklabelPut(
    body?: ScannedPaperwork,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<any>(
      "put",
      `${this.basePath}/paperworklabel`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param identityKey
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public paymentsPaycheckSummaryGet(
    identityKey?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<PayCheckSummary>>;
  public paymentsPaycheckSummaryGet(
    identityKey?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<PayCheckSummary>>>;
  public paymentsPaycheckSummaryGet(
    identityKey?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<PayCheckSummary>>>;
  public paymentsPaycheckSummaryGet(
    identityKey?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (identityKey !== undefined && identityKey !== null) {
      queryParameters = queryParameters.set("workdayId", <any>identityKey);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<PayCheckSummary>>(
      "get",
      `${this.basePath}/payments/paychecksummary`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param identityKey
   * @param payStubId
   * @param paymentDate
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public paymentsPaycheckDetailGet(
    identityKey?: string,
    payStubId?: string,
    paymentDate?: String,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<PayCheck>;
  public paymentsPaycheckDetailGet(
    identityKey?: string,
    payStubId?: string,
    paymentDate?: String,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<PayCheck>>;
  public paymentsPaycheckDetailGet(
    identityKey?: string,
    payStubId?: string,
    paymentDate?: String,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<PayCheck>>;
  public paymentsPaycheckDetailGet(
    identityKey?: string,
    payStubId?: string,
    paymentDate?: String,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (identityKey !== undefined && identityKey !== null) {
      queryParameters = queryParameters.set("workdayId", <any>identityKey);
    }
    if (payStubId !== undefined && payStubId !== null) {
      queryParameters = queryParameters.set("payStubId", <any>payStubId);
    }
    if (paymentDate !== undefined && paymentDate !== null) {
      queryParameters = queryParameters.set("paymentDate", <any>paymentDate);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<PayCheck>(
      "get",
      `${this.basePath}/payments/paycheckdetail`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public featureflagsGet(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<AdministrationFeatureFlags>;
  public featureflagsGet(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<AdministrationFeatureFlags>>;
  public featureflagsGet(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<AdministrationFeatureFlags>>;
  public featureflagsGet(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<AdministrationFeatureFlags>(
      "get",
      `${this.basePath}/featureflags`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public storiesPost(
    body?: shareStory,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public storiesPost(
    body?: shareStory,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public storiesPost(
    body?: shareStory,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public storiesPost(
    body?: shareStory,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<any>("post", `${this.basePath}/stories`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   *
   * @param identityKey
   * @param pin
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public pinVerifyGet(
    identityKey?: string,
    pin?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<ProfilePinResponse>;
  public pinVerifyGet(
    identityKey?: string,
    pin?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<ProfilePinResponse>>;
  public pinVerifyGet(
    identityKey?: string,
    pin?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<ProfilePinResponse>>;
  public pinVerifyGet(
    identityKey?: string,
    pin?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (identityKey !== undefined && identityKey !== null) {
      queryParameters = queryParameters.set("identityKey", <any>identityKey);
    }
    if (pin !== undefined && pin !== null) {
      queryParameters = queryParameters.set("pin", <any>pin);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ProfilePinResponse>(
      "get",
      `${this.basePath}/pin/verify`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param identityKey
   * @param pin
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public pinPatch(
    identityKey?: string,
    pin?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public pinPatch(
    identityKey?: string,
    pin?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public pinPatch(
    identityKey?: string,
    pin?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public pinPatch(
    identityKey?: string,
    pin?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (identityKey !== undefined && identityKey !== null) {
      queryParameters = queryParameters.set("identityKey", <any>identityKey);
    }
    if (pin !== undefined && pin !== null) {
      queryParameters = queryParameters.set("pin", <any>pin);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>("patch", `${this.basePath}/pin`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public challengeVerifyPost(
    body?: ProfileChallengeQuestion,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<ProfileChallengeQuestionResponse>;
  public challengeVerifyPost(
    body?: ProfileChallengeQuestion,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<ProfileChallengeQuestionResponse>>;
  public challengeVerifyPost(
    body?: ProfileChallengeQuestion,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<ProfileChallengeQuestionResponse>>;
  public challengeVerifyPost(
    body?: ProfileChallengeQuestion,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<ProfileChallengeQuestionResponse>(
      "post",
      `${this.basePath}/challenge/verify`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public mapFeedbackPost(
    body?: MapFeedback,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public mapFeedbackPost(
    body?: MapFeedback,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public mapFeedbackPost(
    body?: MapFeedback,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public mapFeedbackPost(
    body?: MapFeedback,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<any>(
      "post",
      `${this.basePath}/map/feedback`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public challengePost(
    body?: ChallengeQuestion,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public challengePost(
    body?: ChallengeQuestion,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public challengePost(
    body?: ChallengeQuestion,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public challengePost(
    body?: ChallengeQuestion,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<any>("post", `${this.basePath}/challenge`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   *
   * @param queryText
   * @param sessionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public messagebotGet(
    queryText?: string,
    sessionId?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<AdministrationMessageBot>;
  public messagebotGet(
    queryText?: string,
    sessionId?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<AdministrationMessageBot>>;
  public messagebotGet(
    queryText?: string,
    sessionId?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<AdministrationMessageBot>>;
  public messagebotGet(
    queryText?: string,
    sessionId?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (queryText !== undefined && queryText !== null) {
      queryParameters = queryParameters.set("queryText", <any>queryText);
    }
    if (sessionId !== undefined && sessionId !== null) {
      queryParameters = queryParameters.set("sessionId", <any>sessionId);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<AdministrationMessageBot>(
      "get",
      `${this.basePath}/messagebot`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public newsArticleGet(
    id?: number,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<CompanyNewsArticle>;
  public newsArticleGet(
    id?: number,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<CompanyNewsArticle>>;
  public newsArticleGet(
    id?: number,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<CompanyNewsArticle>>;
  public newsArticleGet(
    id?: number,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (id !== undefined && id !== null) {
      queryParameters = queryParameters.set("id", <any>id);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<CompanyNewsArticle>(
      "get",
      `${this.basePath}/news/article`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param limit
   * @param pageNumber
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public newsGet(
    limit?: number,
    pageNumber?: number,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<CompanyNewsArticle>>;
  public newsGet(
    limit?: number,
    pageNumber?: number,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<CompanyNewsArticle>>>;
  public newsGet(
    limit?: number,
    pageNumber?: number,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<CompanyNewsArticle>>>;
  public newsGet(
    limit?: number,
    pageNumber?: number,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set("limit", <any>limit);
    }
    if (pageNumber !== undefined && pageNumber !== null) {
      queryParameters = queryParameters.set("pageNumber", <any>pageNumber);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<CompanyNewsArticle>>(
      "get",
      `${this.basePath}/news`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param identityKey
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public challengeUserGet(
    identityKey?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<ChallengeQuestion>;
  public challengeUserGet(
    identityKey?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<ChallengeQuestion>>;
  public challengeUserGet(
    identityKey?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<ChallengeQuestion>>;
  public challengeUserGet(
    identityKey?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (identityKey !== undefined && identityKey !== null) {
      queryParameters = queryParameters.set("identityKey", <any>identityKey);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ChallengeQuestion>(
      "get",
      `${this.basePath}/challenge/user`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public challengeGet(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<ChallengeQuestion>>;
  public challengeGet(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<ChallengeQuestion>>>;
  public challengeGet(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<ChallengeQuestion>>>;
  public challengeGet(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<ChallengeQuestion>>(
      "get",
      `${this.basePath}/challenge`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param identityKey
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public orientationGet(
    identityKey: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<OrientationDetails>;
  public orientationGet(
    identityKey: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<OrientationDetails>>;
  public orientationGet(
    identityKey: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<OrientationDetails>>;
  public orientationGet(
    identityKey: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (identityKey === null || identityKey === undefined) {
      throw new Error(
        "Required parameter identityKey was null or undefined when calling orientationGet."
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (identityKey !== undefined && identityKey !== null) {
      queryParameters = queryParameters.set("identityKey", <any>identityKey);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<OrientationDetails>(
      "get",
      `${this.basePath}/orientation`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public linksGet(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Links>;
  public linksGet(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Links>>;
  public linksGet(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Links>>;
  public linksGet(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Links>("get", `${this.basePath}/links`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   *
   * @param identityKey
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public agreementGet(
    identityKey?: string,
    consentType?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<UserAgreementResponse>;
  public agreementGet(
    identityKey?: string,
    consentType?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<UserAgreementResponse>>;
  public agreementGet(
    identityKey?: string,
    consentType?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<UserAgreementResponse>>;
  public agreementGet(
    identityKey?: string,
    consentType?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (identityKey !== undefined && identityKey !== null) {
      queryParameters = queryParameters.set("identityKey", <any>identityKey);
    }
    if (consentType !== undefined && consentType !== null) {
      queryParameters = queryParameters.set("consentType", <any>consentType);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<UserAgreementResponse>(
      "get",
      `${this.basePath}/agreement`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public agreementPost(
    body?: UserAgreement,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public agreementPost(
    body?: UserAgreement,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public agreementPost(
    body?: UserAgreement,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public agreementPost(
    body?: UserAgreement,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<any>("post", `${this.basePath}/agreement`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public referencelibraryGet(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<ReferenceLibrarySectionItem>>;
  public referencelibraryGet(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<ReferenceLibrarySectionItem>>>;
  public referencelibraryGet(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<ReferenceLibrarySectionItem>>>;
  public referencelibraryGet(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<ReferenceLibrarySectionItem>>(
      "get",
      `${this.basePath}/referencelibrary`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param keyword
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public referencelibrarySearchGet(
    keyword?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<ReferenceLibraryLibraryMetadata>;
  public referencelibrarySearchGet(
    keyword?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<ReferenceLibraryLibraryMetadata>>;
  public referencelibrarySearchGet(
    keyword?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<ReferenceLibraryLibraryMetadata>>;
  public referencelibrarySearchGet(
    keyword?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (keyword !== undefined && keyword !== null) {
      queryParameters = queryParameters.set("keyword", <any>keyword);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ReferenceLibraryLibraryMetadata>(
      "get",
      `${this.basePath}/referencelibrary/search`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param sectionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public referencelibrarySectionGet(
    sectionId?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<ReferenceLibrarySubSectionItem>>;
  public referencelibrarySectionGet(
    sectionId?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<ReferenceLibrarySubSectionItem>>>;
  public referencelibrarySectionGet(
    sectionId?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<ReferenceLibrarySubSectionItem>>>;
  public referencelibrarySectionGet(
    sectionId?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (sectionId !== undefined && sectionId !== null) {
      queryParameters = queryParameters.set("sectionId", <any>sectionId);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<ReferenceLibrarySubSectionItem>>(
      "get",
      `${this.basePath}/referencelibrary/section`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public employeeresourcesGet(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<ReferenceLibraryQuickLinkItem>>;
  public employeeresourcesGet(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<ReferenceLibraryQuickLinkItem>>>;
  public employeeresourcesGet(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<ReferenceLibraryQuickLinkItem>>>;
  public employeeresourcesGet(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<ReferenceLibraryQuickLinkItem>>(
      "get",
      `${this.basePath}/employeeresources`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public messagingRecipientsGet(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<MessagesRecipient>>;
  public messagingRecipientsGet(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<MessagesRecipient>>>;
  public messagingRecipientsGet(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<MessagesRecipient>>>;
  public messagingRecipientsGet(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<MessagesRecipient>>(
      "get",
      `${this.basePath}/messaging/recipients`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public messagingSendmessagePost(
    body?: ComposeMessageCriteria,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public messagingSendmessagePost(
    body?: ComposeMessageCriteria,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public messagingSendmessagePost(
    body?: ComposeMessageCriteria,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public messagingSendmessagePost(
    body?: ComposeMessageCriteria,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<any>(
      "post",
      `${this.basePath}/messaging/sendmessage`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public messagingDeletemessagePatch(
    body?: MessageThreadCriteria,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<MessageThreadResponse>;
  public messagingDeletemessagePatch(
    body?: MessageThreadCriteria,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<MessageThreadResponse>>;
  public messagingDeletemessagePatch(
    body?: MessageThreadCriteria,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<MessageThreadResponse>>;
  public messagingDeletemessagePatch(
    body?: MessageThreadCriteria,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<MessageThreadResponse>(
      "patch",
      `${this.basePath}/messaging/deletemessage`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public messagingReplytomessagePost(
    body?: ReplyMessageCriteria,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public messagingReplytomessagePost(
    body?: ReplyMessageCriteria,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public messagingReplytomessagePost(
    body?: ReplyMessageCriteria,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public messagingReplytomessagePost(
    body?: ReplyMessageCriteria,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<any>(
      "post",
      `${this.basePath}/messaging/replytomessage`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param identityKey
   * @param threadId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public messagingThreadGet(
    identityKey?: string,
    threadId?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<MessageThreadDetailResponse>;
  public messagingThreadGet(
    identityKey?: string,
    threadId?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<MessageThreadDetailResponse>>;
  public messagingThreadGet(
    identityKey?: string,
    threadId?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<MessageThreadDetailResponse>>;
  public messagingThreadGet(
    identityKey?: string,
    threadId?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (identityKey !== undefined && identityKey !== null) {
      queryParameters = queryParameters.set("identityKey", <any>identityKey);
    }
    if (threadId !== undefined && threadId !== null) {
      queryParameters = queryParameters.set("threadId", <any>threadId);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<MessageThreadDetailResponse>(
      "get",
      `${this.basePath}/messaging/thread`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param identityKey
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public messagingThreadsGet(
    identityKey?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<MessagesThreadListResponse>;
  public messagingThreadsGet(
    identityKey?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<MessagesThreadListResponse>>;
  public messagingThreadsGet(
    identityKey?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<MessagesThreadListResponse>>;
  public messagingThreadsGet(
    identityKey?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (identityKey !== undefined && identityKey !== null) {
      queryParameters = queryParameters.set("identityKey", <any>identityKey);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<MessagesThreadListResponse>(
      "get",
      `${this.basePath}/messaging/threads`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param identityKey
   * @param currentQuestionGroupId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public profileDrivingOptionsGet(
    identityKey?: string,
    currentQuestionGroupId?: number,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<DrivingOptionsQuestionnaire>;
  public profileDrivingOptionsGet(
    identityKey?: string,
    currentQuestionGroupId?: number,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<DrivingOptionsQuestionnaire>>;
  public profileDrivingOptionsGet(
    identityKey?: string,
    currentQuestionGroupId?: number,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<DrivingOptionsQuestionnaire>>;
  public profileDrivingOptionsGet(
    identityKey?: string,
    currentQuestionGroupId?: number,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (identityKey !== undefined && identityKey !== null) {
      queryParameters = queryParameters.set("identityKey", <any>identityKey);
    }
    if (
      currentQuestionGroupId !== undefined &&
      currentQuestionGroupId !== null
    ) {
      queryParameters = queryParameters.set(
        "currentQuestionGroupId",
        <any>currentQuestionGroupId
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    const baseUrl = `${this.basePath}/profile/drivingOptions`;
    return this.httpClient.request<DrivingOptionsQuestionnaire>(
      "get",
      baseUrl,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public profileDrivingOptionsPost(
    body?: DrivingOptionsSaveDrivingOptionCriteria,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<DrivingOptionsQuestionnaireValidationModel>>;
  public profileDrivingOptionsPost(
    body?: DrivingOptionsSaveDrivingOptionCriteria,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<
    HttpResponse<Array<DrivingOptionsQuestionnaireValidationModel>>
  >;
  public profileDrivingOptionsPost(
    body?: DrivingOptionsSaveDrivingOptionCriteria,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<DrivingOptionsQuestionnaireValidationModel>>>;
  public profileDrivingOptionsPost(
    body?: DrivingOptionsSaveDrivingOptionCriteria,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<
      Array<DrivingOptionsQuestionnaireValidationModel>
    >("post", `${this.basePath}/profile/drivingOptions`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   *
   * @param identityKey
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public messagingLegacymessagebannercardGet(
    identityKey?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<BannerMessage>;
  public messagingLegacymessagebannercardGet(
    identityKey?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<BannerMessage>>;
  public messagingLegacymessagebannercardGet(
    identityKey?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<BannerMessage>>;
  public messagingLegacymessagebannercardGet(
    identityKey?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (identityKey !== undefined && identityKey !== null) {
      queryParameters = queryParameters.set("identityKey", <any>identityKey);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<BannerMessage>(
      "get",
      `${this.basePath}/messaging/bannerMessage`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param userId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public gamificationBalanceGet(
    userId?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<GamificationBalance>;
  public gamificationBalanceGet(
    userId?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<GamificationBalance>>;
  public gamificationBalanceGet(
    userId?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<GamificationBalance>>;
  public gamificationBalanceGet(
    userId?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (userId !== undefined && userId !== null) {
      queryParameters = queryParameters.set("userId", <any>userId);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<GamificationBalance>(
      "get",
      `${this.basePath}/gamification/balance`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param userId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public gamificationBadgesGet(
    userId?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<GamificationBadgeResponse>>;
  public gamificationBadgesGet(
    userId?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<GamificationBadgeResponse>>>;
  public gamificationBadgesGet(
    userId?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<GamificationBadgeResponse>>>;
  public gamificationBadgesGet(
    userId?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (userId !== undefined && userId !== null) {
      queryParameters = queryParameters.set("userId", <any>userId);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<GamificationBadgeResponse>>(
      "get",
      `${this.basePath}/gamification/badges`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public referralMessagesGet(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<ReferralsReferralMessage>>;
  public referralMessagesGet(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<ReferralsReferralMessage>>>;
  public referralMessagesGet(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<ReferralsReferralMessage>>>;
  public referralMessagesGet(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<ReferralsReferralMessage>>(
      "get",
      `${this.basePath}/referral/messages`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public gamificationProfilePost(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public gamificationProfilePost(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public gamificationProfilePost(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public gamificationProfilePost(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<any>(
      "post",
      `${this.basePath}/gamification/profile`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param keyword
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public referencelibraryHierarchyGet(
    keyword?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<ReferenceLibraryReferenceSection>>;
  public referencelibraryHierarchyGet(
    keyword?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<ReferenceLibraryReferenceSection>>>;
  public referencelibraryHierarchyGet(
    keyword?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<ReferenceLibraryReferenceSection>>>;
  public referencelibraryHierarchyGet(
    keyword?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (keyword !== undefined && keyword !== null) {
      queryParameters = queryParameters.set("keyword", <any>keyword);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<ReferenceLibraryReferenceSection>>(
      "get",
      `${this.basePath}/referencelibrary/hierarchy`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param stakeholderId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public loadsGet(
    stakeholderId: number,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<JobLoad>>;
  public loadsGet(
    stakeholderId: number,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<JobLoad>>>;
  public loadsGet(
    stakeholderId: number,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<JobLoad>>>;
  public loadsGet(
    stakeholderId: number,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (stakeholderId === null || stakeholderId === undefined) {
      throw new Error(
        "Required parameter stakeholderId was null or undefined when calling loadsGet."
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (stakeholderId !== undefined && stakeholderId !== null) {
      queryParameters = queryParameters.set(
        "stakeholderId",
        <any>stakeholderId
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<JobLoad>>(
      "get",
      `${this.basePath}/loads`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public loadsPost(
    body?: JobLoadAssignment,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public loadsPost(
    body?: JobLoadAssignment,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public loadsPost(
    body?: JobLoadAssignment,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public loadsPost(
    body?: JobLoadAssignment,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<any>("post", `${this.basePath}/loads`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public profileIdsGet(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<ProfileIdsResponse>;
  public profileIdsGet(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<ProfileIdsResponse>>;
  public profileIdsGet(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<ProfileIdsResponse>>;
  public profileIdsGet(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    console.log("request service api call");
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      console.log(
        "request service access token",
        this.configuration.accessToken
      );
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      console.log(
        "request service access token function",
        accessToken.toString()
      );
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    console.log("request service before api call");

    // to determine the Content-Type header
    const consumes: string[] = [];
    console.log("request service this.httpclient", this.httpClient);
    console.log("request service this.basePath", this.basePath);
    console.log(
      "request service this.configuration.withCredentials",
      this.configuration.withCredentials
    );
    headers.get("Authorization");
    console.log("headers", JSON.stringify(headers));
    console.log(
      "Authorization Headers request service",
      headers.get("Authorization")
    );
    const response = this.httpClient.request<ProfileIdsResponse>(
      "get",
      `${this.basePath}/profile/ids`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
    const pipeResponse = response.pipe(
      map((res) => {
        // Log each property of the response
        for (const key in res) {
          console.log("key", key);
          if (res.hasOwnProperty(key)) {
            console.log(`Property: ${key}, Value: ${res[key]}`);
          }
        }
        return res as ProfileIdsResponse;
      })
    );
    console.log("request service piperesponse", JSON.stringify(pipeResponse));
    return response;
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public profileImageGet(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<ProfileImageResponse>;
  public profileImageGet(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<ProfileImageResponse>>;
  public profileImageGet(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<ProfileImageResponse>>;
  public profileImageGet(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ProfileImageResponse>(
      "get",
      `${this.basePath}/profile/image`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param stakeholderId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public profilePermissionsGet(
    stakeholderId?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<ProfilePermissionResponse>;
  public profilePermissionsGet(
    stakeholderId?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<ProfilePermissionResponse>>;
  public profilePermissionsGet(
    stakeholderId?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<ProfilePermissionResponse>>;
  public profilePermissionsGet(
    stakeholderId?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (stakeholderId !== undefined && stakeholderId !== null) {
      queryParameters = queryParameters.set(
        "stakeholderId",
        <any>stakeholderId
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ProfilePermissionResponse>(
      "get",
      `${this.basePath}/profile/permissions`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param stakeholderId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public profileFleetmanagersGet(
    stakeholderId?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<ProfileFleetManagersResponse>;
  public profileFleetmanagersGet(
    stakeholderId?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<ProfileFleetManagersResponse>>;
  public profileFleetmanagersGet(
    stakeholderId?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<ProfileFleetManagersResponse>>;
  public profileFleetmanagersGet(
    stakeholderId?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (stakeholderId !== undefined && stakeholderId !== null) {
      queryParameters = queryParameters.set(
        "stakeholderId",
        <any>stakeholderId
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ProfileFleetManagersResponse>(
      "get",
      `${this.basePath}/profile/fleetmanagers`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param workdayId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public profileInfoGet(
    workdayId?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<ProfileInfo>;
  public profileInfoGet(
    workdayId?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<ProfileInfo>>;
  public profileInfoGet(
    workdayId?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<ProfileInfo>>;
  public profileInfoGet(
    workdayId?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (workdayId !== undefined && workdayId !== null) {
      queryParameters = queryParameters.set("workdayId", <any>workdayId);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ProfileInfo>(
      "get",
      `${this.basePath}/profile/info`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param stakeholderId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public profileDivisionGet(
    stakeholderId?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<ProfileDivisionInfoResponse>;
  public profileDivisionGet(
    stakeholderId?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<ProfileDivisionInfoResponse>>;
  public profileDivisionGet(
    stakeholderId?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<ProfileDivisionInfoResponse>>;
  public profileDivisionGet(
    stakeholderId?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (stakeholderId !== undefined && stakeholderId !== null) {
      queryParameters = queryParameters.set(
        "stakeholderId",
        <any>stakeholderId
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ProfileDivisionInfoResponse>(
      "get",
      `${this.basePath}/profile/division`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
