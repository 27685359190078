import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { PingAuthGuardService } from "./auth/ping-auth-guard.service";
import { PingCallbackComponent } from "./modules/ping-callback/ping-callback.component";

const routes: Routes = [
  {
    path: "",
    canActivate: [PingAuthGuardService],
    loadChildren: () =>
      import("./modules/landing/landing.module").then(
        (l) => l.LandingPageModule
      ),
  },
  {
    path: "implicit/callback",
    component: PingCallbackComponent,
  },
  {
    path: "implicit/logout",
    loadChildren: () =>
      import("./auth/implicit/end-session/end-session.module").then(
        (en) => en.EndSessionPageModule
      ),
  },
  {
    path: "login",
    loadChildren: () =>
      import("./login/login.module").then((li) => li.LoginPageModule),
  },
  {
    path: "welcome",
    loadChildren: () =>
      import("./modules/welcome/welcome.module").then((cp) => cp.WelcomeModule),
  },
  { path: "**", redirectTo: "/", pathMatch: "full" },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
