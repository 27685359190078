import {
  createFeatureSelector,
  createSelector,
  MetaReducer,
  ActionReducer,
} from "@ngrx/store";
import * as fromApp from "../app.state";
import { RootActions, RootActionTypes } from "./root.actions";
import { OrientationDetails } from "../Swagger-Gen-V2/model/orientationDetails";
import { AdministrationFeatureFlags } from "../Swagger-Gen-V2/model/administrationFeatureFlags";

export interface State extends fromApp.State {
  rootState: RootState;
}

export interface RootState {
  orientationInfo: OrientationDetails;
  error: string;
  featureFlagIinfo: AdministrationFeatureFlags;
}

export const metaReducers: MetaReducer<fromApp.State>[] = [
  clearStateMetaReducer,
];

const initialState: RootState = {
  orientationInfo: null,
  error: "",
  featureFlagIinfo: null,
};

const rootState = createFeatureSelector<RootState>('root');
export const getOrientationData = createSelector(
    rootState,
    state => (state != null) ? state.orientationInfo : null
);

export const getFeatureFlagStatusData = createSelector(rootState, (state) =>
  state != null ? state.featureFlagIinfo : null
);

export const getNewsItemError = createSelector(
  rootState,
  (state) => state.error
);
export function clearStateMetaReducer(reducer: ActionReducer<fromApp.State>): ActionReducer<fromApp.State> {
    return function (state, action) {
      if (action.type === RootActionTypes.Logout) {
        state = undefined;
      }
      return reducer(state, action);
    };
  }
export function rootReducer(state = initialState, action: RootActions): RootState {
    switch (action.type) {
        case RootActionTypes.GetWelcomeOrientationInfoSuccess:
            return {
                ...state,
                orientationInfo: action.payload
            };
        case RootActionTypes.GetWelcomeOrientationInfoFail:
            return {
                ...state,
                orientationInfo: null,
                error: action.payload
            };
            case RootActionTypes.GetFeatrueFlagsStatusSuccess:
                return {
                    ...state,
                    featureFlagIinfo: action.payload
                };
            case RootActionTypes.GetFeatrueFlagsStatusFail:
                return {
                    ...state,
                    featureFlagIinfo: null,
                    error: action.payload
                };

    default:
      return state;
  }
}
