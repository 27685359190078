import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Observable, lastValueFrom, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { AuthService } from "../auth/auth.service"; // Your auth service that provides the refreshToken method
import { from } from "rxjs";
import { PingAuthService } from "../auth/ping-auth.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.handle(request, next));
  }

  async handle(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Promise<HttpEvent<any>> {
    if (AuthService._isAuthenticated.value) {
      if (AuthService.pingPlatform.is("ios")) {
        var token = await this.authService.getValidToken();
        const authReq = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
        return await next.handle(authReq).toPromise();
      }
      var token = await PingAuthService.getValidToken();
      console.log("TokenInterceptor token", token);
      console.log("Authorization request", request);
      if (
        environment.isEdgeConnectDevice &&
        request.url ===
          "https://auth.pingone.com/d85932c9-e4ac-47b1-88fb-cde2671d1e02/as/token" &&
        request.body
      ) {
        return await next.handle(request).toPromise();
      }
      console.log("Authorization next", next);
      const authReq = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
      return await next.handle(authReq).toPromise();
    } else {
      return next.handle(request).toPromise();
    }
  }

  //   intercept(
  //     request: HttpRequest<any>,
  //     next: HttpHandler
  //   ): Observable<HttpEvent<any>> {
  //     if (this.authService._isAuthenticated.value) {
  //       console.log("TokenInterceptor");
  //       return from(this.authService.getValidToken()).pipe(
  //         switchMap(async (token) => {
  //           console.log("TokenInterceptor token", token);
  //           const authReq = request.clone({
  //             setHeaders: {
  //               Authorization: `Bearer ${token}`,
  //             },
  //           });
  //           return await next.handle(authReq).toPromise();
  //         })
  //       );
  //     } else {
  //       return next.handle(request);
  //     }
  //   }
}
