import { Injectable } from "@angular/core";
import {
  OidcClient,
  TokenResponse,
} from "@pingidentity-developers-experience/ping-oidc-client-sdk";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";
import { IUserInfo } from "./user-info.model";
import PingPlugin from "src/plugins/PingPlugin";
import { HttpHeaders, HttpParams } from "@angular/common/http";
import {Observable } from "rxjs";
import { SubSink } from "subsink";


@Injectable({
  providedIn: "root",
})
export class PingAuthService {
  static oidcClient: Promise<OidcClient>;
  static pingToken: string;
  static pingRefreshToken: string;
  static subscriptions$ = new SubSink();
  static isLoading = false;

  private static apiUrl =
    "https://orchestrate-api.pingone.com/v1/company/d85932c9-e4ac-47b1-88fb-cde2671d1e02/policy/40dcea7341268f664f5e7327ae167dc7/start";
  private static userInfoUrl =
    "https://auth.pingone.com/d85932c9-e4ac-47b1-88fb-cde2671d1e02/as/userinfo";
  private static refreshTokenUrl =
    "https://auth.pingone.com/d85932c9-e4ac-47b1-88fb-cde2671d1e02/as/token";

  static callPostForPS(postData: any): Observable<any> {
    const headers = new HttpHeaders({
      "X-SK-API-Key":
        "63da22184f33ff2f520e5adb63ee4d9c9e0052d50c442e79e5339b9c4f1b98b8bd71d9c25e94d73e0c275c80fcbc8b76808cc87a732aee678ba459db8c2fb67afbb3c94b0deaae3020ad82d13e9e9f05a7670c62c9c7906e6a230d4027fcd9c30762d380331364fe973fdf523f0f999611b5709f111272fe7f318f260606f7ac",
      "Content-Type": "application/json",
    });
    let response = AuthService.pingHttpClient.request<any>(
      "post",
      `${this.apiUrl}`,
      {
        body: postData,
        headers: headers,
      }
    );
    // const response = AuthService.pingHttpClient.post<any>(
    //   this.apiUrl,
    //   postData,
    //   { headers }
    // );
    console.log("API Response from callPostForPS:", response);
    return response;
  }

  static callRefreshTokenForPS(postData: any): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });
    let body = new HttpParams();
    for (const key in postData) {
      if (postData.hasOwnProperty(key)) {
        body = body.set(key, postData[key]);
      }
    }
    let response = AuthService.pingHttpClient.request<any>(
      "post",
      `${this.refreshTokenUrl}`,
      {
        body: body,
        headers: headers,
      }
    );
    // const response = AuthService.pingHttpClient.post<any>(
    //   this.apiUrl,
    //   postData,
    //   { headers }
    // );
    console.log("refresh token response for PS:", response);
    return response;
  }

  static async initializePingAuth() {
    this.oidcClient = OidcClient.initializeFromOpenIdConfig(
      "https://auth.pingone.com/d85932c9-e4ac-47b1-88fb-cde2671d1e02/as",
      environment.pingClientOptions
    );
    console.log("this.oidcClient", this.oidcClient);
    // localStorage.setItem("oidcClient", JSON.stringify(this.oidcClient));
    return this.oidcClient;
  }

  static async initializeAndroidPingAuth() {
    try {
      // await this.getValidToken();
      console.log("initializeAndroidPingAuth called");
      AuthService.token = {
        access_token: "",
        token_type: "",
        expires_in: 0,
        refresh_token: "",
        id_token: "",
        scope: "",
      };
      if (environment.isEdgeConnectDevice) {
        await this.getValidToken();
      } else {
        await PingPlugin.initializePingAuth({}).then((data) => {
          //await this.getAndroidAccessToken();
          if (data != null) {
            console.log("access Token data", data.accessToken);
            PingAuthService.pingToken = data.accessToken;
            AuthService.token.access_token = data.accessToken;
          }
        });
      }
    } catch (error) {
      console.error("Error initializing PingAuth", error);
    }
  }

  static async getValidToken(): Promise<string> {
    if (AuthService.pingPlatform.is("android")) {
      console.log("Auth Service getValidToken called - Android");
      if (
        AuthService._isAuthenticated.value === true &&
        !environment.isEdgeConnectDevice
      ) {
        await PingPlugin.getAndroidAccessToken({}).then((data) => {
          if (data != null) {
            console.log(
              "access Token data from getValidToken method",
              data.accessToken
            );
            AuthService.token = {
              access_token: "",
              token_type: "",
              expires_in: 0,
              refresh_token: "",
              id_token: "",
              scope: "",
            };
            PingAuthService.pingToken = data.accessToken;
            AuthService.token.access_token = data.accessToken;
          }
        });
        // await this.getUserInfoFromToken();
        // await this.getUserInfoFromToken();
      } else if (
        AuthService._isAuthenticated.value === true &&
        environment.isEdgeConnectDevice &&
        PingAuthService.pingToken !== null &&
        PingAuthService.pingToken !== undefined
      ) {
        var isTokenExpired = AuthService.helper.isTokenExpired(
          PingAuthService.pingToken
        );
        console.log("isTokenExpired", isTokenExpired);
        if (isTokenExpired && PingAuthService.isLoading === false) {
          console.log("check refresh token mechanism");
          PingAuthService.isLoading = true;
          const postData = {
            grant_type: "refresh_token",
            refresh_token: PingAuthService.pingRefreshToken,
            client_id: "d90619fb-83ef-4e17-a6eb-1e5f9616cff2",
          };
          this.subscriptions$.sink = PingAuthService.callRefreshTokenForPS(
            postData
          ).subscribe((data) => {
            console.log("refresh Token data from Ping APi", data);
            AuthService.token = {
              access_token: "",
              token_type: "",
              expires_in: 0,
              refresh_token: "",
              id_token: "",
              scope: "",
            };
            PingAuthService.pingToken = data.access_token;
            PingAuthService.pingRefreshToken = data.refresh_token;
            AuthService.token.access_token = data.access_token;
            AuthService.token.refresh_token = data.refresh_token;
            AuthService.setAuthenticated(true);
            PingAuthService.isLoading = false;
          });
        }

        // const tokenData = await AuthService.pingHttpClient.post<any>(
        //   this.refreshTokenUrl,
        //   postData
        // );
        // PingAuthService.isLoading = false;
        // console.log("refresh token data for PS", tokenData);
        console.log("this.isLoading", PingAuthService.isLoading);
      }
      console.log(
        "PingAuthService.pingToken before return",
        PingAuthService.pingToken
      );
      return PingAuthService.pingToken;
    } else if (
      AuthService.pingPlatform.is("desktop") ||
      AuthService.pingPlatform.is("mobileweb")
    ) {
      // localStorage.setItem("oidcClient", JSON.stringify(await this.oidcClient));
      console.log("Auth Service getValidToken called");
      var token: TokenResponse;
      if (await (await this.oidcClient).hasToken()) {
        token = await (await this.oidcClient).getToken();
        try {
          console.log("this.token", token);
          console.log("this.oidcClient", this.oidcClient);
          await this.getUserInfoFromToken();
          //this.userInfo = await oidcClient.fetchUserInfo();
          await AuthService.setAuthenticated(true);
        } catch (error) {
          console.log("Auth Service getValidToken called Fail");
          console.log("this.oidcClient", this.oidcClient);
          const refreshedToken = await this.refreshToken();
          console.log("this.refreshdToken", refreshedToken);
          token = refreshedToken || null;
          console.log("this.token", token);
          await this.getUserInfoFromToken();
          await AuthService.setAuthenticated(true);
        }
        AuthService.token = token;
      } else {
        console.log("Invalid code or state. Redirecting to login page.");
        await AuthService.setAuthenticated(false);
        await AuthService.signOut();
        // authService.router.navigate(["/login"]);
      }
      return token.access_token;
    }
  }

  static async refreshToken(): Promise<TokenResponse | null> {
    if (AuthService.isRefreshingToken) {
      return AuthService.refreshTokenPromise;
    }

    AuthService.isRefreshingToken = true;
    AuthService.refreshTokenPromise = (async () => {
      try {
        const refreshedToken = await (await this.oidcClient).refreshToken();
        AuthService.token = refreshedToken || null;
        return AuthService.token;
      } catch (error) {
        console.error("Error refreshing token", error);
        return null;
      } finally {
        AuthService.isRefreshingToken = false;
        AuthService.refreshTokenPromise = null;
      }
    })();

    return AuthService.refreshTokenPromise;
  }

  static async handleAuthCallback(authService: AuthService) {
    console.log("Auth Service handleAuthCallback called");
    // console.log("authService", authService);
    await PingAuthService.getValidToken();
    console.log(
      "Auth Service handleAuthCallback called - token value is : " +
        AuthService.token.access_token
    );
    await authService.setUserProfile();
    // this.router.navigate([""]);
  }

  static async getUserInfoFromToken(accessToken?: string) {
    if (AuthService.pingPlatform.is("android")) {
      console.log("getUserInfoFromToken called Android");
      if (environment.isEdgeConnectDevice) {
        if (AuthService._isAuthenticated.value == true) {
          console.log("getUserInfoFromToken called EdgeConnect");
          try {
            const response = await PingAuthService.getUserInformationForPS(
              accessToken
            );
            console.log("response from getUserInforFromToken", response);
            console.log(
              "userInfo from getUserInfoFromToken",
              AuthService.userInfo
            );
            console.log("userInfo.givenName", AuthService.userInfo.given_name);
          } catch (error) {
          
            console.error("User Info API call error", error);
          }
        }

        // AuthService.userInfo = {
        //   name: "Chevy",
        //   identityKey: "41cc032f-44a0-4595-8059-0e41552d7963",
        //   user_name: "Chevy",
        //   preferred_username: "Chevy",
        //   given_name: "Chevy",
        // };
      } else {
        await PingPlugin.fetchUserInfo({}).then(
          (response: { userInfo: Object }) => {
            console.log("userInfo", response.userInfo);
            var name = null;
            if (response.userInfo["given_name"] != null) {
              name = response.userInfo["given_name"];
            }
            if (response.userInfo["middle_name"] != null) {
              name = name + " " + response.userInfo["middle_name"];
            }
            if (response.userInfo["family_name"] != null) {
              name = name + " " + response.userInfo["family_name"];
            }
            AuthService.userInfo = {
              name: name,
              identityKey: response.userInfo["identityKey"],
              user_name: response.userInfo["preferred_username"],
              preferred_username: response.userInfo["preferred_username"],
              given_name: response.userInfo["given_name"],
            };
            console.log(
              "AuthService.userInfo.givenName",
              response.userInfo["given_name"]
            );
          }
        );
      }
    } else if (
      AuthService.pingPlatform.is("desktop") ||
      AuthService.pingPlatform.is("mobileweb")
    ) {
      await (await this.oidcClient)
        ?.fetchUserInfo()
        .then((userInfo: IUserInfo) => {
          AuthService.userInfo = userInfo;
        });
    }
    return AuthService.userInfo;
  }

  private static async getUserInformationForPS(accessToken: string) {
    let headers = new HttpHeaders().set(
      "Authorization",
      `Bearer ${accessToken}`
    );
    const response = await AuthService.pingHttpClient
      .get<any>(this.userInfoUrl, { headers })
      .toPromise();
    AuthService.userInfo = response;
    return response;
  }
}
