import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  Router
} from "@angular/router";
import { AuthService } from "./auth.service";
import { NavController } from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class PingAuthGuardService implements CanActivate {

  constructor(private router: Router, private authService: AuthService) { }

  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {

    return new Promise<boolean>((resolve) => {
      this.authService.isAuthenticated.subscribe((isAuthenticated) => {
        if (!isAuthenticated) {
          this.router.navigate(['/login']);
          resolve(false);
        } else {
          resolve(true);
        }
      });
    });

    // If the user is not authenticated
    // if (!authenticated) {
    //   this.router.navigate(['/login']);
    //   return false;  
    // }
    // return true;
  }
}