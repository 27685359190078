import { Injectable, OnDestroy } from "@angular/core";
import * as UserDataInfo from "../state/user-profile.reducer";
import { select, Store } from "@ngrx/store";
import { SubSink } from "subsink";
import { AuthService } from "src/app/auth/auth.service";
import { RequestService } from "src/app/Swagger-Gen-V2";
import * as UserDataActions from "../../../modules/profile-v2/state/user-profile.actions";
import { DispatchService } from "src/app/shared/services/dispatchers/dispatch.service";
@Injectable()
export class ProfileInfoService implements OnDestroy {
  identityKey: string = "";
  private subscriptions$ = new SubSink();

  constructor(
    private userStore: Store<UserDataInfo.State>,
    private authService: AuthService,
    private requestService: RequestService,
    private dispatchService: DispatchService
  ) {}

  async getUserProfileInfo() {
    this.identityKey = await this.authService.getUserIdentitityKey();
    return new Promise((resolve, reject) => {
      this.subscriptions$.sink = this.userStore
        .pipe(select(UserDataInfo.getUserBasicInfo))
        .subscribe(
          (profile) => {
            if (profile) {
              console.log(" UserProfileBasicInfo ProfileInfoService: getUserProfileInfo: profile", profile.user.lastName);
              resolve(profile);
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  async getUserPermissions() {
    return new Promise((resolve, reject) => {
      this.subscriptions$.sink = this.userStore
        .pipe(select(UserDataInfo.getUserPermission))
        .subscribe(
          async (profile) => {
            if (profile) {
              resolve(profile);
            } else {
              const stakeHolderId =
                await this.dispatchService.getStackholderId();
              this.userStore.dispatch(
                new UserDataActions.UserPermissions(stakeHolderId.toString())
              );
            }
          },
          (error) => {
            reject(new Error(error));
          }
        );
    });
  }

  async getFleetManagers() {
    return new Promise((resolve, reject) => {
      this.subscriptions$.sink = this.userStore
        .pipe(select(UserDataInfo.getProfileFleetManagers))
        .subscribe(
          async (profile) => {
            if (profile) {
              resolve(profile);
            } else {
              const stakeHolderId =
                await this.dispatchService.getStackholderId();
              this.userStore.dispatch(
                new UserDataActions.UserProfileFleetManager(
                  stakeHolderId.toString()
                )
              );
            }
          },
          (error) => {
            reject(new Error(error));
          }
        );
    });
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}