import { AfterViewInit, Component, Input, OnDestroy } from "@angular/core";
import { NavController, Platform } from "@ionic/angular";
import { AuthService } from "../../../auth/auth.service";
import { IUserInfo } from "../../../auth/user-info.model";
import { MessagingService, UserDisplayVM } from "../../../Swagger-Gen";
import { Preferences } from "@capacitor/preferences";
import { AnalyticsService } from "../../../shared/services/analytics.service";
import { MenuController } from "@ionic/angular";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { SubSink } from "subsink";
import { MenuItemsService } from "../services/menu-items-service";
import { ProfileUtility } from "src/app/shared/utilities/profile-utility";
import { Browser } from "ionic-appauth";
import { environment } from "src/environments/environment";
import { Profile } from "src/app/Swagger-Gen-V2";
import { ProfileInfoService } from "../../profile-v2/service/profileInfo.service";
import { TelemetryUtility } from "src/app/shared/utilities/telemetry-utilities";
import { TelemetryConstants } from "src/app/shared/utilities/telemetry-constants";
import { AdministrationFeatureFlags } from "src/app/Swagger-Gen-V2/model/administrationFeatureFlags";
import { DispatchService } from "src/app/shared/services/dispatchers/dispatch.service";
@Component({
  selector: "app-hamburger-menu",
  templateUrl: "./hamburger-menu.component.html",
  styleUrls: ["./hamburger-menu.component.scss"],
})
export class HamburgerMenuComponent implements AfterViewInit, OnDestroy {
  themeIsDark = false;
  isProduction: boolean = environment.production;
  userFullName = "";
  userProfileImageUrl = ""; // Stores the url of the user profile image
  OPPORTUNITIES_PAGE_SELECTED_TAB_KEY = "opportunities-page-selected-tab";
  UNREAD_MESSAGES_COUNT_KEY = "unread-messages-count";
  public defaultProfileUrl = "/src/assets/Images/default-user-avatar.jpg";
  subscriptions$ = new SubSink();
  menuItems: any[];
  userInfo: IUserInfo;
  memberProfileInfo: any;
  userProfile: UserDisplayVM = {};
  unreadMessagesCount = 0;
  isWorkdayDown: boolean = false;
  isProfileDownTimeFlag: boolean = false;
  viewProfileDisable: boolean = false;
  workdayPachecks: boolean = false;
  identityKey = "";
  adminSubMenuItems: {
    iconType: string;
    label: string;
    icon: string;
    url: string;
    cls: string;
    visible: boolean;
  }[];
  showSubmenu: boolean = true;
  @Input("jobsCount") jobsCount: number = 0;
  @Input("benefitsCount") benefitsCount: number = 0;
  @Input("userImage") userImage: string;
  SAFETY_PAGE_SELECTED_TAB_KEY = "safety-page-selected-tab";
  CONTACTS_PAGE_KEY = "contacts";
  oppurtunityMsg = "Clicked Career Opportunities On Menu";
  benefitsMsg = "Clicked Benefits On Menu";
  BENEFITS_SELECTED_TAB_KEY = "benefits-selected-tab";
  BENEFITS_PAGE_KEY = "benefits, health and wellness";
  profile: Profile;
  featureFlags: AdministrationFeatureFlags;

  // Instantiates the component
  constructor(
    private navCtrl: NavController,
    private authService: AuthService,
    private messagingService: MessagingService,
    private analytics: AnalyticsService,
    private telemetryUtility: TelemetryUtility,
    private menu: MenuController,
    private iab: InAppBrowser,
    private menuItemsService: MenuItemsService,
    private platform: Platform,
    private browser: Browser,
    private profileUtility: ProfileUtility,
    private profileInfoService: ProfileInfoService,
    private dispatchService: DispatchService
  ) {
    this.subscriptions$.sink = this.menuItemsService
      .getMenuItems()
      .subscribe((menuItems) => {
        this.menuItems = menuItems;
      });

    this.subscriptions$.sink = this.menuItemsService
      .getAdminSubMenuItems()
      .subscribe((subMenuItems) => {
        this.adminSubMenuItems = subMenuItems;
      });
  }

  async ngOnInit() {
    this.identityKey = await this.authService.getUserIdentitityKey();
    this.profile = await this.profileInfoService.getUserProfileInfo();
    await this.getUserProfileInfo();
    this.featureFlags = await this.dispatchService.getFeatureFlags();
    this.isWorkdayDown =
      this.featureFlags &&
      this.featureFlags.flags &&
      this.featureFlags.flags.IsWorkdayDown !== undefined
        ? this.featureFlags.flags.IsWorkdayDown
        : null;

    this.isProfileDownTimeFlag =
      this.featureFlags &&
      this.featureFlags.flags &&
      this.featureFlags.flags.ProfileDownTime !== undefined
        ? this.featureFlags.flags.ProfileDownTime
        : null;

    this.viewProfileDisable = this.isWorkdayDown || this.isProfileDownTimeFlag;

    this.workdayPachecks =
      this.featureFlags &&
      this.featureFlags.flags &&
      this.featureFlags.flags.WorkdayPayChecks !== undefined
        ? this.featureFlags.flags.WorkdayPayChecks
        : null;
  }

  // Handler called when the component is first constructed
  async ngAfterViewInit() {
    await this.getUserInfo();
  }

  // Enables us to navigate to a given
  async routeToScreen(route: string) {
    this.menu.close();
    await this.trackClickEventOnMenuItem(route);
    await this.navCtrl.navigateForward(route);
  }

  // Enables the user to log out
  async logOut() {
    this.analytics.trackEvent(
      "User",
      "Clicked Sign Out On Menu",
      "Clicked-Sign-Out-On-Menu"
    );
    await this.telemetryUtility.recordTelemetry(
      TelemetryConstants.CLICK_SIGN_OUT
    );
    this.menu.close();
    await AuthService.signOut();
  }

  // Tracks a click event on a menu item in google analytics
  async trackClickEventOnMenuItem(route: string) {
    switch (route.toLowerCase()) {
      case "/welcome":
        this.analytics.trackEvent(
          "User",
          "Clicked Welcome On Menu",
          "Clicked-Welcome-On-Menu"
        );
        this.telemetryUtility.recordTelemetry(
          TelemetryConstants.CLICK_WELCOME_MENU
        );
        break;
      case "/loads":
        this.analytics.trackEvent(
          "User",
          "Clicked Jobs On Menu",
          "Clicked-Jobs-On-Menu"
        );
        this.telemetryUtility.recordTelemetry(
          TelemetryConstants.CLICK_JOBS_MENU
        );
        break;
      case "/pin":
        this.analytics.trackEvent(
          "User",
          "Clicked Pay On Menu",
          "Clicked-Pay-On-Menu"
        );
        this.telemetryUtility.recordTelemetry(
          TelemetryConstants.CLICK_PAY_MENU
        );
        break;
      case "/contact":
        this.analytics.trackEvent(
          "User",
          "Clicked Contacts On Menu",
          "Clicked-Contacts-On-Menu"
        );
        this.telemetryUtility.recordTelemetry(
          TelemetryConstants.CLICK_CONTACT_MENU
        );
        break;
      case "/reference-library":
        this.analytics.trackEvent(
          "User",
          "Clicked Reference Library On Menu",
          "Clicked-Reference-Library-On-Menu"
        );
        this.telemetryUtility.recordTelemetry(
          TelemetryConstants.CLICK_REFERENCE_LIBRARY_MENU
        );
        break;
      case "/opportunities":
        this.analytics.trackEvent(
          "User",
          this.oppurtunityMsg,
          "Clicked-Opportunities-On-Menu"
        );
        this.telemetryUtility.recordTelemetry(
          TelemetryConstants.CLICK_OPPURTUNITY_MENU
        );
        break;
      case "/maps":
        this.analytics.trackEvent(
          "User",
          "Clicked Map On Menu",
          "Clicked-Map-On-Menu"
        );
        this.telemetryUtility.recordTelemetry(
          TelemetryConstants.CLICK_MAP_MENU
        );
        break;
      case "/news":
        this.analytics.trackEvent(
          "User",
          "Clicked Werner News On Menu",
          "Clicked-Werner-News-On-Menu"
        );
        this.telemetryUtility.recordTelemetry(
          TelemetryConstants.CLICK_NEWS_MENU
        );
        break;
      case "/safety":
        this.analytics.trackEvent(
          "User",
          "Clicked Safety On Menu",
          "Clicked-Safety-On-Menu"
        );
        this.telemetryUtility.recordTelemetry(
          TelemetryConstants.CLICK_SAFETY_MENU
        );
        break;
      case "/customize-home":
        this.analytics.trackEvent(
          "User",
          "Clicked Customize Your Home Screen On Menu",
          "Clicked-Customize-Your-Home-Screen-On-Menu"
        );
        this.telemetryUtility.recordTelemetry(
          TelemetryConstants.CLICK_CUSTOMIZE_MENU
        );
        break;
      case "profile":
        this.analytics.trackEvent(
          "User",
          "Clicked Profile On Menu",
          "Clicked-Profile-On-Menu"
        );
        this.telemetryUtility.recordTelemetry(
          TelemetryConstants.CLICK_PROFILE_MENU
        );
        break;
      case "/settings":
        this.analytics.trackEvent(
          "User",
          "Clicked Settings On Menu",
          "Clicked-Settings-On-Menu"
        );
        this.telemetryUtility.recordTelemetry(
          TelemetryConstants.CLICK_SETTINGS_MENU
        );
        break;
      case "/messages":
        this.analytics.trackEvent(
          "User",
          "Clicked Messages On Menu",
          "Clicked-Messages-On-Menu"
        );
        this.telemetryUtility.recordTelemetry(
          TelemetryConstants.CLICK_MESSAGES_MENU
        );
        break;
      case "/benefits":
        this.analytics.trackEvent("User", this.benefitsMsg, this.benefitsMsg);
        this.telemetryUtility.recordTelemetry(
          TelemetryConstants.CLICK_BENEFITS_MENU
        );
        break;
    }
  }

  public async getUserInfo(): Promise<void> {
    this.identityKey = await this.authService.getUserIdentitityKey();
  }

  // Retrieves the user profile information
  async getUserProfileInfo() {
    this.userFullName = this.profileUtility.getV2UserFullName(
      this.profile.user
    );
  }

  // when taping on the opportunities menu screen we make sure that we select the search tab by default
  showOpportunitiesScreen() {
    Preferences.remove({ key: this.OPPORTUNITIES_PAGE_SELECTED_TAB_KEY });
    Preferences.set({
      key: this.OPPORTUNITIES_PAGE_SELECTED_TAB_KEY,
      value: "0",
    });
  }

  showSafetyScreen() {
    Preferences.set({ key: this.SAFETY_PAGE_SELECTED_TAB_KEY, value: "0" });
  }

  showContactsScreen() {
    localStorage.setItem("contact-selected-tab", "0");
  }

  async showBenefitsScreen() {
    await Preferences.set({ key: this.BENEFITS_SELECTED_TAB_KEY, value: "0" });
  }

  openExternal(type: string) {}

  // computes the number of unread messages that the user has
  countUnreadMessages(userIdentityKey: string) {
    if (userIdentityKey !== null && userIdentityKey !== undefined) {
      this.subscriptions$.sink = this.messagingService
        .apiMessagingMessagesCountunreadIdentityKeyGet(userIdentityKey)
        .subscribe((t) => {
          this.unreadMessagesCount = t;
          this.saveUnreadMessagesCount();
        });
    }
  }

  // saves the number of unread messages into the local storage
  async saveUnreadMessagesCount() {
    await Preferences.remove({ key: this.UNREAD_MESSAGES_COUNT_KEY });
    await Preferences.set({
      key: this.UNREAD_MESSAGES_COUNT_KEY,
      value: this.unreadMessagesCount.toString(),
    });
  }

  // retrieves  the number of unread messages from the storage
  async getUnreadMessagesCountFromStorage() {
    let messagesCount: number = 0;
    const unreadMessagesCountJSON = await Preferences.get({
      key: this.UNREAD_MESSAGES_COUNT_KEY,
    });

    if (
      unreadMessagesCountJSON !== null &&
      unreadMessagesCountJSON !== undefined &&
      unreadMessagesCountJSON.value !== null &&
      unreadMessagesCountJSON.value !== undefined
    ) {
      messagesCount = parseInt(unreadMessagesCountJSON.value);
    }
    return messagesCount;
  }

  // Close the menu and redirect
  menuIetmClicked(url: string, label: string) {
    let menuItemClicked = label.toLowerCase();
    if (
      !(
        (menuItemClicked === "pay" &&
          this.isWorkdayDown &&
          this.workdayPachecks) ||
        (menuItemClicked === "workday learning" && this.isWorkdayDown)
      )
    ) {
      if (url !== null && url.indexOf("http") >= 0) {
        if (menuItemClicked === "workday learning" && this.platform.is("ios")) {
          this.browser.showWindow(url);
        } else {
          this.iab.create(url, "_blank");
        }
      } else {
        return this.routeToScreen(url);
      }
      // close side menu , after menu clicked
      const self = this;
      setTimeout(() => self.menu.toggle(), 250);
    }
  }

  subMenuItemHandler(): void {
    this.showSubmenu = !this.showSubmenu;
  }

  async ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}
